import cx from 'classnames'
import { useContext } from 'react'

import { SiteContext } from '@lib/site-context'

interface MegaNavigationBackdropProps {
  isOpen: boolean
}

const MegaNavigationBackdrop = ({ isOpen }: MegaNavigationBackdropProps) => {
  const { toggleMegaNavigation } = useContext(SiteContext)

  return (
    <div
      role="presentation"
      onClick={() => toggleMegaNavigation(false)}
      onBeforeInput={() => toggleMegaNavigation(false)}
      className={cx(
        'hidden lg:block fixed inset-0 z-0 bg-backdrop bg-opacity-40 transition-opacity duration-150 ease-linear',
        {
          'opacity-0 pointer-events-none': !isOpen,
          'opacity-100 pointer-events-auto backdrop-blur-[6px]': isOpen,
        },
      )}
    />
  )
}

export default MegaNavigationBackdrop
