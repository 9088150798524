import { type PortableTextComponentProps } from '@portabletext/react'
import dynamic from 'next/dynamic'

import { type SanityVideoEmbed } from '@data/sanity/queries/types/content'
import { getYouTubeVideoId } from '@lib/video'

const MuxVideo = dynamic(() => import('@components/video/mux-video'))

const VideoEmbed = ({
  value,
}: PortableTextComponentProps<SanityVideoEmbed>) => {
  switch (value.type) {
    case 'youtube': {
      const { youtubeVideoUrl } = value
      const youtubeVideoId = getYouTubeVideoId(youtubeVideoUrl)

      if (!youtubeVideoId) {
        return null
      }

      return (
        <div className="relative w-full pt-[calc((9/16)*100%)]">
          <div className="absolute inset-0">
            <iframe
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              title="YouTube video player"
              allow="encrypted-media"
              allowFullScreen
              width="100%"
              height="100%"
              className="border-0"
            ></iframe>
          </div>
        </div>
      )
    }

    case 'mux': {
      const { muxVideo, aspectRatio, settings, borderRadius } = value

      if (!muxVideo) {
        return null
      }

      return (
        <MuxVideo
          video={muxVideo}
          showControls={settings?.controls}
          autoplay={settings?.autoplay}
          loop={settings?.loop}
          muted={settings?.muted}
          borderRadius={borderRadius}
          customAspectRatio={aspectRatio}
        />
      )
    }

    default: {
      return null
    }
  }
}

export default VideoEmbed
