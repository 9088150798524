import { type PortableTextComponentProps } from '@portabletext/react'

import { type SanityInfoBox } from '@data/sanity/queries/types/content'

import SimplePortableText from '@components/simple-portable-text'

type InfoBoxValue = Pick<SanityInfoBox, 'title' | 'content'>

const InfoBox = ({ value }: PortableTextComponentProps<InfoBoxValue>) => {
  const { title, content } = value

  return (
    <div className="info-box bg-pageBG text-pageText rounded-md p-6 sm:p-8">
      {title && (
        <h4 className="!mb-4 text-[1.375rem] leading-[1.625rem]">{title}</h4>
      )}

      {content && (
        <SimplePortableText
          content={content}
          className="whitespace-pre-wrap !my-0 text-[1.125rem] leading-[1.625rem]"
        />
      )}
    </div>
  )
}

export default InfoBox
