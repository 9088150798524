import cx from 'classnames'
import { contrastColor } from 'contrast-color'
import { type CSSProperties, type ReactNode } from 'react'

import { type SanityColor } from '@data/sanity/queries/types/color'

interface CSSPropertiesWithSwatch extends CSSProperties {
  '--swatchColor': string
  '--swatchBorder': string
}

interface SwatchProps {
  color: SanityColor
  label: string
  isActive?: boolean
  isCrossed?: boolean
  className?: string
  children?: ReactNode
}

const Swatch = ({
  color,
  label,
  isActive,
  isCrossed = false,
  className,
  children,
}: SwatchProps) => {
  const borderColor = color.hex ? contrastColor({ bgColor: color.hex }) : ''

  const style: CSSPropertiesWithSwatch = {
    '--swatchColor': color.hex,
    '--swatchBorder': borderColor,
  }

  return (
    <div
      className={cx(
        'relative p-0 block rounded-full w-[20px] h-[20px]',
        className,
      )}
      style={{ ...style, backgroundColor: 'var(--swatchColor)' }}
      role="button"
      aria-label={label}
    >
      {/* Inner dim border */}
      <div
        className="absolute inset-0 rounded-full opacity-10 border border-solid"
        style={{ borderColor: 'var(--swatchBorder)' }}
      />

      {/* Outer highlight border */}
      <div
        className={cx(
          'absolute rounded-full border transition-opacity duration-200',
          {
            'border-pageText': isActive,
            'inset-[-3px]': !isCrossed,
            'opacity-100': isActive || isCrossed,
            'border-transparent opacity-0': !isActive && !isCrossed,
            '-inset-1 is-crossed border-transparent': isCrossed && !isActive,
          },
        )}
      />

      {children}
    </div>
  )
}

export default Swatch
