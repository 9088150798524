import { type PortableTextComponentProps } from '@portabletext/react'
import cx from 'classnames'

import { type SanityHorizontalRule } from '@data/sanity/queries/types/content'

type HorizontalRuleValue = Pick<SanityHorizontalRule, 'color' | 'faded'>

const HorizontalRule = ({
  value,
}: PortableTextComponentProps<HorizontalRuleValue>) => {
  return (
    <hr
      className={cx(`bg-${value.color ?? 'current'}`, {
        'opacity-25': value.faded,
      })}
    />
  )
}

export default HorizontalRule
