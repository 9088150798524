import { type PortableTextComponentProps } from '@portabletext/react'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'

import Photo from '@components/photo'

const Figure = ({ value }: PortableTextComponentProps<SanityImageFragment>) => {
  return <Photo image={value} />
}

export default Figure
