import cx from 'classnames'
import { type Variants, motion } from 'framer-motion'
import { useContext, useEffect, useRef, useMemo } from 'react'
import { useResizeObserver } from 'usehooks-ts'

import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'

import FeaturedProducts from './featured-products'
import Menu from './menu'

interface MegaNavigationDropdownProps {
  dropdown: SanityMenuFeaturedLinkFragment
  hasFocus: boolean
  setHasFocus: (hasFocus: boolean) => void
  setActiveDropdownHeight: (activeDropdownHeight: number) => void
}

const swipeAnimation: Variants = {
  show: {
    opacity: 1,
    x: ['-1rem', '0rem'],
    transition: {
      x: {
        duration: 0.8,
        delay: 0.1,
        ease: [0.16, 1, 0.3, 1],
      },
      opacity: {
        duration: 0.2,
        delay: 0.1,
      },
    },
  },
  hide: {
    x: ['0rem', '1rem'],
    opacity: 0,
    transition: {
      x: {
        duration: 0.4,
        ease: [0.16, 1, 0.3, 1],
      },
      opacity: {
        duration: 0.1,
      },
    },
  },
}

const MegaNavigationDropdown = ({
  dropdown,
  hasFocus,
  setHasFocus,
  setActiveDropdownHeight,
}: MegaNavigationDropdownProps) => {
  const strings = useContext(StringsContext)
  const { megaNavigation, toggleMegaNavigation } = useContext(SiteContext)

  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownRectangle = useResizeObserver({
    ref: dropdownRef,
  })

  const isDropdownActive = useMemo(
    () => megaNavigation.isOpen && megaNavigation.activeId === dropdown._key,
    [dropdown._key, megaNavigation.activeId, megaNavigation.isOpen],
  )

  useEffect(() => {
    if (isDropdownActive && typeof dropdownRectangle.height !== 'undefined') {
      setActiveDropdownHeight(dropdownRectangle.height)
    }
  }, [dropdownRectangle.height, isDropdownActive, setActiveDropdownHeight])

  return (
    <div
      ref={dropdownRef}
      id={`meganav-${dropdown._key}`}
      className={cx(
        'absolute top-0 inset-x-0 z-10 -mt-px overflow-hidden transition-visibility duration-200',
        {
          'delay-[0s] pointer-events-auto': isDropdownActive,
          'invisible delay-[.8s] pointer-events-none': !isDropdownActive,
        },
      )}
    >
      <div>
        <div>
          <motion.div
            initial="hide"
            animate={isDropdownActive ? 'show' : 'hide'}
            onAnimationComplete={(definition) =>
              setHasFocus(definition === 'show')
            }
            variants={swipeAnimation}
            className="grid grid-cols-2 relative px-10 pt-4 pb-12 will-change-transform"
          >
            <Menu
              items={dropdown.dropdownItems}
              hasFocus={hasFocus && isDropdownActive}
              onClick={() => toggleMegaNavigation(false)}
              isMegaNavMenu
            />

            {dropdown.featured && dropdown.featured.length > 0 && (
              <div className="grid grid-cols-2 gap-4 max-w-2xl relative w-full pl-8">
                <div className="block absolute top-0 right-full w-2 text-xs uppercase tracking-widest font-semibold">
                  <span className="block overflow-hidden absolute top-1/2 left-0 whitespace-nowrap transform rotate-90 origin-top-left">
                    {strings.featuredProducts}
                  </span>
                </div>
                <FeaturedProducts
                  products={dropdown.featured}
                  onClick={() => toggleMegaNavigation(false)}
                />
              </div>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default MegaNavigationDropdown
