import { type CSSPropertiesWithHeight } from './mega-navigation'

interface MegaNavigationBackgroundProps {
  height: number
}

const MegaNavigationBackground = ({
  height,
}: MegaNavigationBackgroundProps) => {
  const style: CSSPropertiesWithHeight = {
    '--h': height,
    '--hpx': `${height}px`,
  }

  return (
    <div className="hidden lg:block" style={style}>
      <div className="absolute inset-x-0 h-px bg-header-bg will-change-transform origin-top-left scale-y-[var(--h)] transition-transform duration-[.8s] ease-custom-2 z-20 top-0" />
      <div className="absolute inset-x-0 -mt-px translate-x-0 translate-y-[var(--hpx)] top-0" />
    </div>
  )
}

export default MegaNavigationBackground
