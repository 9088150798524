import { type Variants, motion } from 'framer-motion'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import {
  type CSSProperties,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import { useIntersectionObserver, useWindowSize } from 'usehooks-ts'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { type SanityPage } from '@data/sanity/queries/types/page'
import { pageTransitionSpeed } from '@lib/animate'
import { SiteContext } from '@lib/site-context'

import CookieBar from '@modules/shared/cookie-bar'
import Footer from '@modules/shared/footer'
import Header, { type HeaderSizeValues } from '@modules/shared/header'
import VideoModal from '@components/video/video-modal'
import HeadSeo, { type SchemaString } from './head-seo'

interface CSSPropertiesWithHeader extends CSSProperties {
  '--headerHeight'?: string
}

interface LayoutProps {
  site: SanitySiteFragment
  page: SanityPage
  children: ReactNode
  canonicalUrl?: string
  schemas?: SchemaString[]
  hasSmoothScroll?: boolean
  draftMode?: boolean
}

const variants: Variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: pageTransitionSpeed / 1000,
      delay: 0.2,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: pageTransitionSpeed / 1000,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
}

const DraftModeOverlay = () => (
  <div className="sticky bottom-0 inset-x-0 z-90 bg-white bg-opacity-95">
    <div className="flex justify-between items-center container py-1">
      <p className="m-0 text-sm">Preview mode is active.</p>
      <NextLink href="/api/disable-draft" prefetch={false}>
        <button className="underline decoration-1 hover:decoration-2 text-current text-sm">
          Exit preview mode
        </button>
      </NextLink>
    </div>
  </div>
)

const Layout = ({
  site,
  page,
  canonicalUrl,
  schemas,
  hasSmoothScroll,
  draftMode,
  children,
}: LayoutProps) => {
  const { setHeaderHeight } = useContext(SiteContext)

  const scrollContainerRef = useRef(null)
  const { ref: topObserverRef, isIntersecting } = useIntersectionObserver({
    freezeOnceVisible: false,
    threshold: 1,
  })
  const { route } = useRouter()
  const windowSize = useWindowSize()

  // Save relevant header sizes
  const [headerSizeStyle, setHeaderSizeStyle] =
    useState<CSSPropertiesWithHeader>({})

  // Set window height lock (with Safari/iOS hack)
  const [lockHeight, setLockHeight] = useState(false)
  const hasChin =
    typeof window !== 'undefined' &&
    !!navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    !!navigator.userAgent.match(/AppleWebKit/)

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof windowSize !== 'undefined' &&
      !lockHeight
    ) {
      document.body.style.setProperty('--vh', `${windowSize.height * 0.01}px`)
      setLockHeight(hasChin)
    }
  }, [hasChin, lockHeight, windowSize])

  const hasTransparentHeader =
    'hasTransparentHeader' in page && !!page.hasTransparentHeader

  const handleHeaderResize = useCallback(
    ({ height }: HeaderSizeValues) => {
      setHeaderHeight(height)

      setHeaderSizeStyle({
        '--headerHeight': `${height}px`,
      })
    },
    [setHeaderHeight],
  )

  let pageContent = (
    <div style={headerSizeStyle}>
      <Header
        menuDesktopLeft={site.headerSettings?.menuDesktopLeft}
        menuDesktopRight={site.headerSettings?.menuDesktopRight}
        menuMobilePrimary={site.headerSettings?.menuMobilePrimary}
        menuMobileSecondary={site.headerSettings?.menuMobileSecondary}
        promoSettings={site.promoSettings}
        logo={site.identitySettings?.logo}
        invertedLogo={site.identitySettings?.invertedLogo}
        isTransparent={hasTransparentHeader}
        onResize={handleHeaderResize}
        isInView={isIntersecting}
      />
      <div
        className="min-h-screen flex flex-col justify-between overflow-x-hidden"
        data-scroll-container={hasSmoothScroll}
        ref={scrollContainerRef}
      >
        <main id="content" data-scroll-content={hasSmoothScroll}>
          <span
            ref={topObserverRef}
            className="relative w-full h-0 pointer-events-none"
            style={{
              top: 'var(--headerHeight)',
            }}
          />

          {children}
        </main>
        <Footer
          content={site.footerSettings?.content}
          menu={site.footerSettings?.menu}
          social={site.footerSettings?.social}
          copyright={site.footerSettings?.copyright}
          logo={site.identitySettings?.invertedLogo}
        />
      </div>
      <VideoModal />
    </div>
  )

  if (site.generalSettings?.pageAnimation) {
    pageContent = (
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={variants}
      >
        {pageContent}
      </motion.div>
    )
  }

  if (hasSmoothScroll) {
    pageContent = (
      <LocomotiveScrollProvider
        options={{ smooth: true }}
        watch={[route]}
        containerRef={scrollContainerRef}
      >
        {pageContent}
      </LocomotiveScrollProvider>
    )
  }

  return (
    <>
      <HeadSeo
        site={site}
        page={page}
        canonicalUrl={canonicalUrl}
        schemas={schemas}
      />

      {site.cookieSettings?.enabled && (
        <CookieBar
          enabled={site.cookieSettings.enabled}
          message={site.cookieSettings.message}
          link={site.cookieSettings.link}
        />
      )}

      {pageContent}

      {!!draftMode && <DraftModeOverlay />}
    </>
  )
}

export default Layout
