import cx from 'classnames'
import { type ButtonHTMLAttributes, useContext, useState } from 'react'

import { type SanityAddToCartButton } from '@data/sanity/queries/types/content'
import { CartContext } from '@lib/cart/context'
import { getPrice } from '@lib/helpers'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

import Button, { ButtonProps, ButtonSize } from './button'

type AddToCartButtonProps = ButtonProps &
  Pick<SanityAddToCartButton, 'productVariant'> & {
    onAddToCartError?: () => void
  }

const AddToCartButton = ({
  variant,
  size,
  color,
  icon,
  iconAlignment,
  children,
  className,
  productVariant,
  onAddToCartError,
}: AddToCartButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { cart, openCartAfterAddingItems, addItemsToCart, toggleCart } =
    useContext(CartContext)
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [isAddToCartError, setIsAddToCartError] = useState(false)

  const handleAddToCart = async () => {
    setIsAddToCartError(false)
    setIsAddingToCart(true)

    const isSuccessful = await addItemsToCart([
      {
        id: productVariant.variantID,
        quantity: 1,
      },
    ])

    if (!isSuccessful && onAddToCartError) {
      onAddToCartError()
    }

    setIsAddingToCart(false)

    if (isSuccessful && openCartAfterAddingItems) {
      toggleCart(true)
    }
  }

  return (
    <span className={cx('inline-flex items-center', className)}>
      <span
        className={cx('font-serif font-medium mr-5', {
          'text-sm': size === ButtonSize.SMALL,
          'text-base': size === ButtonSize.NORMAL,
          'text-2xl': size === ButtonSize.LARGE,
        })}
      >
        {currency}
        {getPrice(productVariant.price, taxRate)}
      </span>

      <Button
        id="product-add-to-cart-button"
        variant={variant}
        size={size}
        color={color}
        icon={icon}
        iconAlignment={iconAlignment}
        onClick={handleAddToCart}
        disabled={
          !cart || !productVariant.inStock || isAddingToCart || isAddToCartError
        }
      >
        {!cart && strings.buttonUnavailable}
        {isAddingToCart && strings.buttonAdding}
        {!productVariant.inStock && strings.productOutOfStock}
        {cart &&
          productVariant.inStock &&
          !isAddingToCart &&
          !isAddToCartError &&
          children}
      </Button>
    </span>
  )
}

export default AddToCartButton
