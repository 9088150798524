import NextLink from 'next/link'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { type SanityFooterSettings } from '@data/sanity/queries/types/site'

import Menu from '@blocks/navigation/menu'
import Photo from '@components/photo'
import ComplexPortableText from '@components/complex-portable-text'

type FooterProps = SanityFooterSettings & {
  logo?: SanityImageFragment
}

const Footer = ({ content, menu, social, copyright, logo }: FooterProps) => {
  return (
    <footer
      className="relative overflow-hidden bg-pageText text-pageBG pt-20 pb-10"
      role="contentinfo"
    >
      <div className="container flex flex-col gap-y-16 sm:gap-y-28">
        {logo && (
          <NextLink href="/" className="inline-flex">
            <Photo image={logo} />
          </NextLink>
        )}

        <div className="flex flex-wrap justify-between gap-8 sm:gap-10">
          {content && (
            <div className="rc md:max-w-[50%]">
              <ComplexPortableText content={content} />
            </div>
          )}

          <div className="flex flex-wrap gap-x-20 gap-y-8 sm:gap-y-10">
            {social && (
              // TODO: Could use `Menu` component here
              <ul className="flex flex-col">
                {social.map((link) => (
                  <li key={link._key}>
                    <a
                      id={`footer-social-link-${link.socialNetwork?.toLowerCase()}`}
                      className="opacity-50 hover:opacity-100 transition-opacity duration-200 inline-flex text-xl text-left !no-underline"
                      key={link._key}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.socialNetwork}
                    </a>
                  </li>
                ))}
              </ul>
            )}

            {menu && (
              <Menu items={menu.items} isFooterMenu className="min-w-[200px]" />
            )}
          </div>
        </div>

        <div className="flex flex-wrap justify-between items-center gap-6">
          <p className="opacity-50">{copyright}</p>

          <div className="sm:min-w-[200px]">
            <a
              href="https://www.co2neutralwebsite.com/certificate/6147/en"
              target="_blank"
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                className="h-[50px]"
                src="https://www.co2neutralwebsite.com/icons/badge/regular_en.svg"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
