import cx from 'classnames'

import MenuItem, { type ItemType } from './menu-item'

interface MenuProps {
  id?: string
  items: ItemType[]
  hasFocus?: boolean
  useMegaNav?: boolean
  onClick?: () => void
  className?: string
  isFooterMenu?: boolean
  isHeaderDesktopMenu?: boolean
  isHeaderMobilePrimaryMenu?: boolean
  isHeaderMobileSecondaryMenu?: boolean
  isMegaNavMenu?: boolean
}

const Menu = ({
  id,
  items,
  hasFocus = true,
  useMegaNav = false,
  onClick,
  isFooterMenu,
  isHeaderDesktopMenu,
  isHeaderMobilePrimaryMenu,
  isHeaderMobileSecondaryMenu,
  isMegaNavMenu,
  className,
}: MenuProps) => {
  return (
    <ul
      className={cx(
        {
          'flex flex-col gap-y-1': isFooterMenu,
          'flex flex-wrap': isHeaderDesktopMenu,
          'flex flex-col gap-y-3':
            isHeaderMobilePrimaryMenu || isHeaderMobileSecondaryMenu,
        },
        className,
      )}
    >
      {items.map((item) => {
        return (
          <li
            key={item._key}
            className={cx({
              'mx-2 my-0 inline-flex flex-col justify-center align-center':
                isHeaderDesktopMenu,
            })}
          >
            <MenuItem
              menuId={id}
              item={item}
              hasFocus={hasFocus}
              onClick={onClick}
              useMegaNav={useMegaNav}
              isFooterMenuItem={isFooterMenu}
              isHeaderDesktopMenuItem={isHeaderDesktopMenu}
              isHeaderMobilePrimaryMenuItem={isHeaderMobilePrimaryMenu}
              isHeaderMobileSecondaryMenuItem={isHeaderMobileSecondaryMenu}
              isMegaNavMenuItem={isMegaNavMenu}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default Menu
