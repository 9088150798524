import FocusTrap from 'focus-trap-react'
import {
  type CSSProperties,
  type KeyboardEvent,
  useContext,
  useEffect,
  useState,
} from 'react'

import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { getActiveLinkCount } from '@lib/navigation'
import { SiteContext } from '@lib/site-context'

import MegaNavigationBackdrop from './mega-navigation-backdrop'
import MegaNavigationBackground from './mega-navigation-background'
import MegaNavigationDropdown from './mega-navigation-dropdown'

export interface CSSPropertiesWithHeight extends CSSProperties {
  '--h': number
  '--hpx': string
}

interface MegaNavigationProps {
  items: SanityMenuFeaturedLinkFragment[]
  headerHeight: number
}

const MegaNavigation = ({ items, headerHeight }: MegaNavigationProps) => {
  const { megaNavigation, toggleMegaNavigation } = useContext(SiteContext)

  const [hasFocus, setHasFocus] = useState(false)
  const [activeDropdownHeight, setActiveDropdownHeight] = useState<number>()

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', megaNavigation.isOpen)
  }, [megaNavigation.isOpen])

  const dropdowns = items.filter(
    (item) => item._type === 'navDropdown' && 'dropdownItems' in item,
  )

  if (dropdowns.length === 0) {
    return null
  }

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLDivElement>) => {
    if (key === 'Escape') {
      toggleMegaNavigation(false)
    }
  }

  const activeLinkCount = getActiveLinkCount(
    dropdowns,
    megaNavigation.isOpen,
    megaNavigation.activeId,
  )

  return (
    <>
      <FocusTrap
        active={megaNavigation.isOpen && hasFocus && activeLinkCount > 0}
        focusTrapOptions={{
          allowOutsideClick: true,
        }}
      >
        <div
          role="menu"
          tabIndex={-1}
          onKeyDown={handleKeyDown}
          className="container hidden lg:block absolute top-full inset-x-0 z-30 bg-pageBG"
        >
          {dropdowns.map((dropdown) => (
            <MegaNavigationDropdown
              key={dropdown._key}
              dropdown={dropdown}
              hasFocus={hasFocus}
              setHasFocus={setHasFocus}
              setActiveDropdownHeight={setActiveDropdownHeight}
            />
          ))}
        </div>
      </FocusTrap>

      <MegaNavigationBackground
        height={
          megaNavigation.isOpen ? headerHeight + (activeDropdownHeight ?? 0) : 0
        }
      />
      <MegaNavigationBackdrop isOpen={megaNavigation.isOpen} />
    </>
  )
}

export default MegaNavigation
